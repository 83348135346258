import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-1 bg-white p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.store.state.user)
      ? (_openBlock(), _createBlock(_component_MenuItem, {
          key: 0,
          onClick: _ctx.handleClose,
          link: "/account",
          icon: "camera_alt",
          title: "menu.signin"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MenuItem, {
      onClick: _ctx.handleClose,
      link: "/",
      icon: "man",
      title: "menu.top"
    }, null, 8, ["onClick"]),
    (_ctx.store.state.user)
      ? (_openBlock(), _createBlock(_component_MenuItem, {
          key: 1,
          onClick: _ctx.handleClose,
          link: "/mypage",
          icon: "camera_alt",
          title: "menu.mypage"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.store.state.user)
      ? (_openBlock(), _createBlock(_component_MenuItem, {
          key: 2,
          onClick: _ctx.logout,
          icon: "add_circle_outline",
          title: "menu.signout"
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}